.mainbody{
    background-color: #fff;
    margin: 0 5%;

}

.mainbody_top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.main_top_right{
    display: flex;
    align-items: center;
}

.main_top_center{
    display: flex;
    box-sizing: content-box;
    align-items: center;
    padding: 3px 5px;
    border-radius: 6px;
    font-size: 16px;
}