.root{
    flex-grow: 1;
}
.tab{
    font-size: 12;
    color: #5f6368;
    text-transform: uppercase;
    height: 10;
    font-weight: 600;
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
}

.tabs{
    height: 10;
}

.tab:focus{
    color: #1a73e8;
    border-bottom: 3px solid #1a73e8;
}
.tab:active{
    color: #1a73e8;
    border-bottom: 3px solid #1a73e8;
}