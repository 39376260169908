.template_section {
  background-color: #f4f4f9;
  padding-bottom: 40px;
  padding-top: 7px;
  background-color: rgba(255, 255, 255, 0.1);
}

.template_top {
  margin-left: 160px;
  margin-right: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.gallery_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}

.card1 {
  margin-left: 20px;
  margin-top: 15px;
  display: grid;
}

.card_image {
  box-sizing: border-box;
  height: 200px;
  width: 250px;
  cursor: pointer;
  border-radius: 8px;
  border: 0.2px solid #fffdf7;
  transition: 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card_image:hover {
  border: 1px solid #6e2594;
  filter: grayscale(2);
}

.template_body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 10%;
}

.card_title {
  font-size: 14px;
  margin-top: 2px;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  margin: 10px 10px 10px 65px;
  width: 100px;
  text-transform: uppercase;
  background-color: rgb(78, 119, 216);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
}

.card_title:hover {
  color: #000;
  background-color: rgb(0, 60, 255);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.link {
  text-decoration: none;
}

/* Add this to your Template.css file */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
