body {
  margin: 0;
}

.categoryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  padding: 20px;

  margin: auto; /* Center the container horizontally by default */
  max-width: 1500px;
  align-items: center;
  /* Adjust the maximum width as needed */
}

.card {
  width: 100%;
  max-width: 400px;
  height: 290px;
  gap: 20px;
  margin: auto; /* Center the container horizontally by default */
  text-align: center;
  align-items: center;
}

.categoryImage {
  width: 320px;
  height: 200px;
  object-fit: cover;
  transition: filter 0.3s ease-in-out;
}

.categoryName {
  margin-top: 10px;
  transition: color 0.3s ease-in-out;
  font-size: 16px;
}

.cardHover:hover .categoryImage {
  filter: grayscale(100%);
}

.cardHover:hover .categoryName {
  color: #fff;
}

/* Adjust as needed for responsiveness */
#form-dialog-title {
  width: 90%;
}

@media (max-width: 560px) {
  .categoryContainer {
    grid-template-columns: 1fr;
    gap: 5px; /* Adjust the gap between categories for smaller screens */
    align-items: center;
  }
}

@media (max-width: 400px) {
  .categoryContainer {
    margin: auto; /* Center the container horizontally for smaller screens */
  }
}

.delete {
  padding: 20px;
  margin: 20px;
}

.buttons {
  margin-top: 15px;
}

#form-dialog-title {
  margin: auto;
}

.buttonsContainer {
  margin-top: 10px;
}

.catName {
  text-align: justify;
  margin: auto;
}

.new_image_select {
  margin-top: 20px;
}

.current_image_headline {
  margin: 10px;
}

/* ../path-to-your-styles.css */

.truncated-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; /* Adjust as needed based on your design */
}

.categoryname1 {
  max-width: 300px; /* Set your desired max-width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  font-size: 16px;

  color: rgb(255, 255, 255); /* Set your desired font color */

  /* Show full text on hover */
  &:hover {
    color: rgb(255, 255, 255); /* Change font color on hover */
    white-space: initial; /* Show full text on hover */
  }
}

.new_image {
  margin-top: 20px;
}

.twobuttons {
  margin-top: 25px;
  align-items: right;
}
